import React from 'react';
import styles from './styles/termsandconditions.module.css';
import Header from './Header';
import FooterSection from './FooterSection';
const TermsAndConditions = () => {
  return (
    <>
    <Header/>
    <div className={styles.container}>
      <h1 className={styles.heading1}>Terms and Conditions</h1>
      <p className={styles.paragraph}><strong>Last updated:</strong> [04-09-2024]</p>

      <p>Welcome to   Astroping. These Terms and Conditions ("Terms") govern your use of our website [https://astroping.com/] and our services. By accessing or using our website and services, you agree to comply with these Terms. If you do not agree with these Terms, please do not use our website or services.</p>

      <h2 className={styles.heading2}>1. Use of Our Website</h2>
      <p>You agree to use our website only for lawful purposes and in accordance with these Terms. You are responsible for ensuring that your use of our website does not violate any applicable laws or regulations.</p>

      <h2 className={styles.heading2}>2. Intellectual Property</h2>
      <p>All content, trademarks, and other intellectual property rights on our website are owned by us or our licensors. You may not use, reproduce, or distribute any content from our website without our express written permission.</p>

      <h2 className={styles.heading2}>3. User Accounts</h2>
      <p>To access certain features of our website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>

      <h2 className={styles.heading2}>4. Limitation of Liability</h2>
      <p>Our website and services are provided on an "as is" and "as available" basis. We make no warranties or representations about the accuracy or completeness of the content on our website. We are not liable for any damages arising from your use of our website or services.</p>

      <h2 className={styles.heading2}>5. Indemnification</h2>
      <p>You agree to indemnify and hold us harmless from any claims, losses, liabilities, damages, costs, or expenses (including reasonable attorneys' fees) arising out of or in connection with your use of our website or violation of these Terms.</p>

      <h2 className={styles.heading2}>6. Changes to These Terms</h2>
      <p>We may update these Terms from time to time. We will notify you of any significant changes by posting the updated Terms on our website. Your continued use of our website and services after such changes constitutes your acceptance of the new Terms.</p>

      <h2 className={styles.heading2}>7. Governing Law</h2>
      <p>These Terms are governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].</p>

     
    </div>
    <FooterSection/>
    </>
  );
}

export default TermsAndConditions;
