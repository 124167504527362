import React from 'react';
import styles from './styles/disclaimer.module.css'; 
import Header from './Header';
import FooterSection from './FooterSection';
const Disclaimer = () => {
  return (
    <>
    <Header/>
    <div className={styles.disclaimerContainer}>
      <h1 className={styles.disclaimerHeading}>Disclaimer</h1>
      <p className={styles.disclaimerText}>
        The information provided by our website is for Astronomic purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.
      </p>
      <p className={styles.disclaimerText}>
        Under no circumstance shall we have any liability to you for any loss or damage of any kind incurred as a result of the use of the site or reliance on any information provided on the site. Your use of the site and your reliance on any information on the site is solely at your own risk.
      </p>
      <p className={styles.disclaimerText}>
        The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability, or completeness by us.
      </p>
    </div>
    <FooterSection/>
    </>
  );
};

export default Disclaimer;

