import styles from './styles/BlogSection.module.css'; 
import Header from "./Header"
import FooterSection from "./FooterSection"
const BlogSection=()=>{
    return (
        <>
            <Header/>
            <div className={styles.BlogContainer} >
                <div className={styles.BlogContent}>
                    <h1>Daily Horoscopes: What’s Written in the Stars?</h1>
                    <p>Discover what the universe has in store for you today! Check your daily horoscope to gain insights into your love life, career, and personal growth.
                    Understanding how the stars align can help you navigate through life with more clarity and intention.</p>
                </div>
    
                <div className={styles.BlogContent}>
                    <h1> The Moon’s Influence on Your Emotions</h1>
                    <p>Did you know that the moon can affect your emotions and mood? 
                    As the moon moves through different phases and zodiac signs, 
                    it influences your energy and feelings. Here’s how each phase impacts you:</p>
                <ul className={styles.listItems}>
                    <li>New Moon: Time for setting intentions and starting new projects.</li>
                    <li>Waxing Crescent: Building momentum and working on goals.</li>
                    <li>First Quarter: Overcoming challenges and making decisions.</li>
                    <li>Full Moon: High emotional energy; a time of culmination and reflection.</li>
                    <li>Waning Gibbous: Letting go of what no longer serves you.</li>
                </ul>
                </div>
                <div className={styles.BlogContent}>
                    <h1>  Astrology and Career: Finding Your True Path</h1>
                    <p>Astrology can guide you toward a fulfilling career by identifying your strengths and talents based on your zodiac sign. For example:</p>
                    <ul>
                        <li>Aries may thrive in leadership roles.</li>
                        <li>Taurus can excel in finance or the arts.</li>
                        <li>Gemini may find success in communication or marketing roles.</li>
                        <li>Cancer is drawn to nurturing professions like teaching or healthcare.</li>
                        <li>Leo shines in entertainment or public relations.</li>
                        <li>Virgo is suited for detail-oriented work like analysis or healthcare.</li>
                        <li>Libra may excel in law or design.</li>
                        <li>Scorpio thrives in research or investigative roles.</li>
                        <li>Sagittarius loves travel and education careers.</li>
                        <li>Capricorn excels in business or management.</li>
                        <li>Aquarius is drawn to innovation and humanitarian work.</li>
                        <li>Pisces thrives in creative fields like art or counseling.</li>
                    </ul>
                </div>
                <div className={styles.BlogContent}>
                    <h1> Retrogrades: What They Mean for You</h1>
                    <p>You've probably heard of Mercury retrograde, but did you know that all planets experience retrograde periods? When a planet is in retrograde, its energy can feel disrupted, leading to delays, misunderstandings, or the need to revisit past issues.
                        here’s what to watch out for during key planetary retrogrades:</p>
                <ul>
                    <li>Mercury Retrograde: Communication breakdowns, travel delays, and technical glitches.</li>
                    <li>Venus Retrograde: Relationship issues, revisiting old loves, and reevaluating values.</li>
                    <li>Mars Retrograde: Low energy, frustration, and rethinking how you pursue goals.</li>
                    <li>Jupiter Retrograde: Personal growth and philosophical shifts.</li>
                    <li>Saturn Retrograde: Challenges in discipline, structure, and responsibility.</li>
                </ul>
                </div>
                <div className={styles.BlogContent}>
                    <h1>Closing Thoughts: Embrace the Cosmic Journey</h1>
                    <p>Astrology offers a window into the cosmic forces that shape our lives. Whether you’re looking to understand your personality, relationships, or career path, exploring the stars can provide guidance, insight, and self-awareness.
                    Keep following our blog for more astrology tips, horoscopes, and cosmic wisdom!</p>
                </div>
            </div>
            <FooterSection/>
        </>
    )
}

export default BlogSection