import React from 'react';
import styles from './styles/refundpolicy.module.css';
import Header from './Header';
import FooterSection from './FooterSection';
const RefundPolicy = () => {
  return (
    <>
    <Header/>
    <div className={styles.container}>
      <h1 className={styles.heading1}>Refund Policy</h1>
      <p className={styles.paragraph}><strong>Last updated:</strong> [04-09-2024]</p>

      <p>Welcome to Astroping. This Refund Policy outlines our policies and procedures regarding refunds and cancellations for our services. Please read this policy carefully before making a purchase.</p>

      <h2 className={styles.heading2}>1. Refunds</h2>
      <p>We offer refunds under the following circumstances:</p>
      <ul className={styles.list}>
        <li className={styles.listItem}>If the service is not as described.</li>
        <li className={styles.listItem}>If you cancel within 24 hours of purchase.</li>
      </ul>

      <h2 className={styles.heading2}>2. Cancellation</h2>
      <p>You may cancel your purchase within 24 hours for a full refund. After 24 hours, cancellations will be subject to a partial refund depending on the time elapsed and the services rendered.</p>

      <h2 className={styles.heading2}>3. How to Request a Refund</h2>
      <p>To request a refund, please contact us at:</p>
      <p><strong>Email:</strong> [vishal@astroping.com]</p>
      <p><strong>Address:</strong> [5th Floor, RR building, Biryani times Oppo, Madhapur, Hyderabad,Telangana 500081]</p>

      <h2 className={styles.heading2}>4. Processing Time</h2>
      <p>Refund requests will be processed within 5-7 business days. You will receive a confirmation email once your refund has been processed.</p>

      <h2 className={styles.heading2}>5. Contact Us</h2>
      <p>If you have any questions or concerns regarding our Refund Policy, please contact us at:</p>
      <p><strong>Email:</strong> [vishal@astroping.com]</p>
    </div>
    <FooterSection/>
    </>
  );
}

export default RefundPolicy;
