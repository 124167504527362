import { useState, useEffect } from "react";
import styles from './styles/dailyHoroscopes.module.css'; 
import axios from 'axios';
import TodaysHoroscope from "./TodaysHoroscopes";
import YesterdaysHoroscopes from "./YesterdaysHoroscopes";
import Header from "./Header";
import { TailSpin } from "react-loader-spinner";
import FooterSection from './FooterSection';
const Dailyhoroscope = () => {
    const [today, setToday] = useState(true);
    const [yesterdayHoroscopes, setYesterdayHoroscopes] = useState([]);
    const [todayHoroscopes, setTodaysHoroscopes] = useState([]);

    const getHoroscopes = async () => {
        try {
            const response = await axios.get("https://api.astroping.com/api/horoscope/get-horoscope");
            const { data } = response;
            const { horoscopes } = data;

            
            const yesterdaysHoroscope = horoscopes.filter(eachHoroscope => eachHoroscope.day === 'Yesterday');
            const todayHoroscopes = horoscopes.filter(eachHoroscope => eachHoroscope.day === 'Today');
            console.log(yesterdayHoroscopes)
            console.log(todayHoroscopes)
           
            setYesterdayHoroscopes(yesterdaysHoroscope);
            setTodaysHoroscopes(todayHoroscopes);

        } catch (error) {
            console.log('Error fetching horoscope:', error);
        }
    };

    useEffect(() => {
        getHoroscopes();
    }, []); 
    const todayHoroscope=()=>{
       return( todayHoroscopes.map((eachHoroscope)=>(
            <TodaysHoroscope key={eachHoroscope.id} todayHoroscope={eachHoroscope}/>
        )))
    }

    const yesterdaysHoroscopeDisplay=()=>{
        return (
            yesterdayHoroscopes.map((eachHoroscope)=>(
                <YesterdaysHoroscopes key={eachHoroscope.id} yesterdayHoroscope={eachHoroscope}/>
            ))
        )
    }

    return (
        <>
        <Header/>
        <div className={styles.horoscopeContainer}>
           
            <div className={styles.toggleContainer}>
               

            <button 
                className={`${styles.toggleSection}  ${today===true ? styles.activeToggle : ''}`} 
            onClick={() => setToday(true)}
                >
              Today's Horoscope
            </button>

                <button    className={`${styles.toggleSection} ${today===false ? styles.activeToggle : ''}`} 
                 onClick={() => setToday(false)}>Yesterday's Horoscope</button>
            </div>

            <div className={styles.horoscopeList}>
                {today ? (
                    todayHoroscopes.length > 0 ? (
                        todayHoroscope()
                    ) : (
                        <TailSpin
                        height="50"
                        width="50"
                        color="#0f5bcf"
                        ariaLabel="loading"
                        />
                    )
                ) : (
                    yesterdayHoroscopes.length > 0 ? (
                        yesterdaysHoroscopeDisplay()
                    ) : (
                        <TailSpin
                            height="80"
                            width="80"
                            color="#0f5bcf"
                            ariaLabel="loading"
                            />
                    )
                )}
            </div>
        </div>
        <FooterSection/>
        </>
    );
};

export default Dailyhoroscope;
