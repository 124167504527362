import { useEffect, useState } from "react";
import "./talkToAstrologer.css";
import Header from "./Header";
import FooterSection from "./FooterSection";
import Loader from "./Loader";
import newlogoastroping from './images/newlogoastroping.png';

const TalkToAstrologer = () => {
  const [astrologers, setAstrologers] = useState([]);
  const [filteredAstrologers, setFilteredAstrologers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [sortOption, setSortOption] = useState(""); // New state for sorting option
  const itemsPerPage = 12;

  const getAstrologerDetails = async () => {
    setIsLoading(true);
    const AllAstrologersApi = "https://api.astroping.com/api/astrologer/get-astrologers";
    const response = await fetch(AllAstrologersApi);
    const data = await response.json();
    const { astrologers } = data;
    setAstrologers(astrologers);
    setFilteredAstrologers(astrologers);
    setIsLoading(false);
  };

  useEffect(() => {
    getAstrologerDetails();
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    filterAstrologers(value, selectedCategory);
  };

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setSelectedCategory(value);
    filterAstrologers(searchTerm, value);
  };

  const filterAstrologers = (searchTerm, category) => {
    const filtered = astrologers.filter((astrologer) => {
      const matchesSearch = 
        astrologer.astrologerName.toLowerCase().includes(searchTerm) ||
        astrologer.known_languages.join(", ").toLowerCase().includes(searchTerm);
      const matchesCategory = category === "all" || astrologer.category === category;
      return matchesSearch && matchesCategory;
    });
    setFilteredAstrologers(filtered);
    setCurrentPage(1); 
  };

  // Sorting logic
  const sortedAstrologers = [...filteredAstrologers].sort((a, b) => {
    if (sortOption === 'costLowToHigh') {
      return a.cost - b.cost; // Low to High cost
    } else if (sortOption === 'costHighToLow') {
      return b.cost - a.cost; // High to Low cost
    } else if (sortOption === 'experienceLowToHigh') {
      return a.experience - b.experience; // Low to High experience
    } else if (sortOption === 'experienceHighToLow') {
      return b.experience - a.experience; // High to Low experience
    }
    return 0; // No sorting
  });

  const totalPages = Math.ceil(sortedAstrologers.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const selectedAstrologers = sortedAstrologers.slice(startIndex, startIndex + itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const categories = ["all", ...new Set(astrologers.map((astrologer) => astrologer.category))];

  const getAstrologersToCall = () => (
    <div className="talk-to-astrologer-container">
      <h1 className="talk-heading">Talk With Astrologers</h1>

      <div className="filter-controls">
        <input
          type="search"
          placeholder="Search astrologers "
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />

        <select value={selectedCategory} onChange={handleCategoryChange} className="category-select">
          {categories.map((category) => (
            <option key={category} value={category}>
              {category === "all" ? "All Categories" : category}
            </option>
          ))}
        </select>

       
        <select value={sortOption} onChange={(e) => setSortOption(e.target.value)} className="sort-select">
          <option value="">Sort By</option>
          <option value="costLowToHigh">Cost: Low to High</option>
          <option value="costHighToLow">Cost: High to Low</option>
          <option value="experienceLowToHigh">Experience: Low to High</option>
          <option value="experienceHighToLow">Experience: High to Low</option>
        </select>
      </div>

      <div className="astrologer-grid">
        {selectedAstrologers.map((astrologer) => (
          <div key={astrologer.phone_number} className="astrologer-card">
            <img
              src={astrologer?.profile_photo ? astrologer.profile_photo : newlogoastroping}
              alt={astrologer.astrologerName}
              className="astrologer-photo"
            />
            <h3>{astrologer.astrologerName}</h3>
            <p>Experience: {astrologer.experience} years</p>
            <p>Cost: ₹{astrologer.cost} / minute</p>
            <p>Category: {astrologer.category}</p>
            <p>Languages: {astrologer.known_languages.join(", ")}</p>
            <a
              href="https://play.google.com/store/apps/details?id=com.kilope.astrotell"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="call-button">Call</button>
            </a>
          </div>
        ))}
      </div>

      <div className="pagination-controls">
        <button
          className="pagination-button"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>{currentPage} of {totalPages}</span>
        <button
          className="pagination-button"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );

  return (
    <>
      <Header />
      <div className="astrologer-talk-main-container">
        {isLoading ? <Loader /> : getAstrologersToCall()}
      </div>
      <FooterSection />
    </>
  );
};

export default TalkToAstrologer;
