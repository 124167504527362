import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";


const firebaseConfig = {
  apiKey: 'AIzaSyARqMU4ItlJXQwbqydQ-9eHhd_QYMp1pqw',
  authDomain: 'astroping-20529.firebaseapp.com',
  projectId: 'astroping-20529',
  storageBucket: 'astroping-20529.appspot.com',
  messagingSenderId: '448783040497',
  appId: '1:448783040497:web:18aa1a079c111472e8ce50',
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

export { auth, googleProvider, signInWithPopup };


