import React, { useEffect } from 'react';

const WhatsAppChat = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://d3mkw6s8thqya7.cloudfront.net/integration-plugin.js';
    script.id = 'aisensy-wa-widget';
    script.setAttribute('widget-id', 'OtleMH');
    script.async = true;

    document.body.appendChild(script);

  
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      {}
    </div>
  );
};

export default WhatsAppChat;
