import styles from './styles/Homeescreen.module.css'
import newlogoastroping from './images/newlogoastroping.png'
import React, { useContext } from 'react';
import UserLogin from './UserLogin';
import { RxHamburgerMenu } from "react-icons/rx";
import { useNavigate } from 'react-router-dom'
import { useState } from 'react';
import { AppContext } from './context/AppContext';

const Header=()=>{
    const { user, loginUser, logoutUser } = useContext(AppContext); 
    const [userName,setUserName] = useState(null)
    const navigate = useNavigate()
    const [isBarAppeared,setIsBarAppeared] =useState(false)

    const onClickToNavigateToHome=()=>{
        navigate("/")
    }

    const onClickToNavigateToBlog=()=>{
        navigate("/blog")
    }

    const onClickToAppearAndDisappearBar=()=>{
        setIsBarAppeared(prevState=>!prevState)
   }
   
   const onClickToNavigateToChatWithAstrologer=()=>{
    navigate("/chatWithAstrologer")
  }

   console.log(user)

    return (
        <div>
        <div className={styles.headercontainer}>
               
                <div  className={styles.subcontaineroneofheaderconatiner}>
                     <img src={newlogoastroping} onClick={onClickToNavigateToHome} alt="" id={styles.horoscope}/>
                     <h1 className="homeAstroping" onClick={onClickToNavigateToHome} >Astroping</h1>
                </div>
                <div className={styles.subcontainertwoofheaderconatiner}>
                    <h1 onClick={onClickToNavigateToHome}>Home</h1>
                    {/* <h1>Free Kundli</h1>
                    <h1>Kundli Matching</h1> */}
                    <h1 onClick={onClickToNavigateToChatWithAstrologer} >Chat With Astrologer</h1>
                    <h1 onClick={onClickToNavigateToBlog}>Blog</h1>
                   <UserLogin/>
                </div>
                <RxHamburgerMenu onClick={onClickToAppearAndDisappearBar}  size={23} className={styles.menubar}/>
          </div>
          <div className={isBarAppeared ? styles.sideBarActive : styles.sideBarInActive}>
                  <h1 onClick={onClickToNavigateToHome}>Home</h1>
                  <hr className={styles.horzontalBar}/>
                  {/* <h1>Free Kundli</h1>
                  <hr className={styles.horzontalBar}/>
                  <h1>Kundli Matching</h1> */}
                  <hr className={styles.horzontalBar}/>
                  <h1>Chat With Astrologer</h1>
                  <hr className={styles.horzontalBar}/>
                  <h1 onClick={onClickToNavigateToBlog}>Blog</h1>
                  <hr className={styles.horzontalBar}/>
                  <div>
                     <UserLogin/>
                  </div>
            </div>
         
          </div>

    )
}

export default Header