import React from 'react'
import styles from './styles/Homeescreen.module.css'
import instagram from './images/instagram.png'
import facebook from './images/Facebook.png'
import linkedin from './images/linkedin.png'
import youtube from './images/youtube.png'
import { useNavigate } from 'react-router-dom';
import newlogoastroping from './images/newlogoastroping.png'
import { IoLogoGooglePlaystore } from 'react-icons/io5'
const FooterSection=()=>{

    const navigate = useNavigate();

            const handleClick = () =>{
                  navigate('/AstrologerRegistration');
            }

             const termsandconditions = () =>{
                  navigate('/terms');
             }

             
             const privacypolicy=()=>{
                  navigate('/privacy')
             }

             const refundpolicy=()=>{
                  navigate('/refund')
             }

             
             const contactus=()=>{
                  navigate('/contactus')
             }


             const disclaimer=()=>{
                  navigate('/disclaimer')
             }
             const dayHoroscope=()=>{
               navigate("/day-horoscope")
            }
            const onClickNavigateToZodiacSigns=()=>{
               navigate("/Zodiac-Signs")
             }
          

    return (
        <div className={styles.footercontainer}>
                  <div className={styles.childcontoffootercontainer}>

                        <div className={styles.leftcontofchildcontoffootercontainer}>
                               <img src={newlogoastroping} alt="" className={styles.websiteLogo} style={{height:'100px',width:'100px'}} id={styles.idnameformedia}/>
                               <div className={styles.subchildofleftcont}>
                                  <h1>Astroping</h1>
                                  <span  id={styles.companyaddress}>5th Floor, RR building, Biryani times Oppo, Madhapur, Hyderabad,Telangana 500081</span>
                                  <div className={styles.classNameforalsoavailablein}>
                                      {/* <img src={instagram} alt="" className={styles.socialNetworkImg} style={{height:'27px',width:'27px'}}/>
                                      <img src={facebook} alt="" className={styles.socialNetworkImg} style={{height:'27px',width:'27px'}}/>
                                      <img src={linkedin} alt="" className={styles.socialNetworkImg} style={{height:'27px',width:'27px'}}/>
                                      <img src={youtube} alt="" className={styles.socialNetworkImg} style={{height:'27px',width:'27px'}}/> */}
                                      <a href="https://www.linkedin.com/company/astroping/" target="_blank" rel="noopener noreferrer">
                                        <img src={linkedin} alt="LinkedIn" style={{ height: '27px', width: '27px' }} />
                                             </a>
                                             <a
                                             href="https://play.google.com/store/apps/details?id=com.kilope.astrotell"
                                             target="_blank"
                                             rel="noopener noreferrer"
                                             >
                                             <IoLogoGooglePlaystore
                                             style={{ height: '27px', width: '27px', cursor: 'pointer', color: 'white', marginLeft: '19px', paddingLeft: '19x' }}
                                             alt=""
                                             />
                                             </a>
                                  </div>
                               </div>
                        </div>

                        <div className={styles.rightcontofchildcontoffootercontainer}>
                              {/* <div className={styles.impotant_links}>
                                      <h1 id={styles.idforquery}>Important Links</h1>
                                      <span onClick={onClickNavigateToZodiacSigns}>Zodiac Signs</span>
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                      
                              </div> */}
                              <div className={styles.impotant_links}>
                                  <h1 id={styles.idforquery}>Astrologer</h1>
                                  <span onClick={handleClick} className={styles.classForLinks}>Astrologer Registration</span>
                                  <h1 id={styles.idforquery}>Corporate Info</h1>
                                  <span onClick={termsandconditions} className={styles.classForLinks}>Terms And Conditions</span>
                                  <span onClick={contactus} className={styles.classForLinks}>ContactUs </span>
                                  <span onClick={refundpolicy} className={styles.classForLinks}>Refund Policy</span>
                                  <span  onClick={disclaimer} className={styles.classForLinks}>Disclaimer</span>
                                  <span onClick={privacypolicy} className={styles.classForLinks}>Privacy Policy</span>
                              </div>
                              <div className={styles.impotant_links}>
                              <h1 id={styles.idforquery}>HoroScope</h1>
                              <span onClick={dayHoroscope}>Daily Horoscope</span>
                              <span onClick={onClickNavigateToZodiacSigns}>Zodiac Signs</span>

                              <span></span>
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                      <span></span>
                              </div>
                        </div>
                        
                  </div>

                  <div className={styles.classNameforcopyrighttext}>
                       <span>Copyright &copy; 2024 Astroping all rights reserved</span>
                  </div>
          </div>

    )
}

export default FooterSection