import React from 'react';
import ReactDOM from 'react-dom/client';
import Routing from './Routing';
import AppProvider from './context/AppContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <AppProvider>
            <Routing/>
        </AppProvider>
    </React.StrictMode>
);



