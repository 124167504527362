import React from 'react';
import styles from './styles/contactus.module.css';
import Header from './Header';
import FooterSection from './FooterSection';
const ContactUs = () => {
  return (
    <>
    <Header/>
    <div className={styles.container}>
      <h1 className={styles.heading1}>Contact Us</h1>
      <p className={styles.paragraph}><strong>We’d love to hear from you!</strong> Please use the following methods to get in touch with us.</p>

      <h2 className={styles.heading2}>1. Contact Information</h2>
      <p><strong>Email:</strong> <a href="mailto:vishal@astroping.com?subject=Hello Vishal&body=I would like to discuss something." 
  className={styles.link}
>
  vishal@astroping.com
</a>
</p>
      <p><strong>Address:</strong>5th Floor, RR building, Biryani times Oppo, Madhapur, Hyderabad,Telangana 500081</p>

      <h2 className={styles.heading2}>2. Contact Form</h2>
      <form className={styles.form}>
        <div className={styles.formGroup}>
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" className={styles.input} required />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" className={styles.input} required />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" className={styles.textarea} required></textarea>
        </div>
        <button type="submit" className={styles.button}>Send Message</button>
      </form>

      <h2 className={styles.heading2}>3. Find Us</h2>
      <div className={styles.mapContainer}>
        <p>We are located at the following address:</p>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.1666297823817!2d78.38519707493629!3d17.45173698344639!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9100624e5715%3A0xf1e976da8108d5d1!2sCredmarg%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1735036232817!5m2!1sen!2sin"
          className={styles.map}
          title="Map showing the location of Astroping"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
    </div>
    <FooterSection/>
    </>
  );
}

export default ContactUs;
