import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Button,
  FormControlLabel,
  Typography,
  Paper,
  Avatar,
} from '@mui/material';
import { IoIosCheckmarkCircle } from 'react-icons/io';
import Header from './Header';
import FooterSection from './FooterSection';
import axios from 'axios';

const AstrologerRegistration = () => {
  const [knownLanguages, setKnownLanguages] = useState([]);
  const [expertIn, setExpertIn] = useState([]);
  const [category, setCategory] = useState([]);
  const [astrologerName, setAstrologerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [experience, setExperience] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);
  const [imageUrl, setImageUrl] = useState('');

  const handleChange = (event, setter) => {
    const value = event.target.value;
    setter(typeof value === 'string' ? value.split(',') : value);
  };

  const onChangeProfileImage = (event) => {
    const imageFile = event.target.files[0];
    const imageLinkUrl = URL.createObjectURL(imageFile);
    setImageUrl(imageLinkUrl);
  };

  const handleRemoveImage = () => {
    setImageUrl(null);
  };

  const onSubmitAstrologerDetails = async (event) => {
    event.preventDefault();
    const astrologerDetails = {
      astrologerName,
      phone_number: phoneNumber,
      expertIn: expertIn.join(','),
      known_languages: knownLanguages.join(','),
      experience,
      category: category.join(','),
    };
    try {
      const response = await axios.post('http://3.94.90.155:8088/api/astropingWebsite/register', astrologerDetails, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log(response);
      setIsRegistered(response.status === 200);
    } catch (error) {
      console.error(error);
      setIsRegistered(false);
    }
  };

  const RegisterSuccessView = () => (
    <Paper elevation={3} style={{ padding: '20px', textAlign: 'center' }}>
      <IoIosCheckmarkCircle size={65} color='blue' />
      <Typography variant="h5">Registered Successfully</Typography>
      <Typography variant="body1">Wait for the Approval....!</Typography>
    </Paper>
  );

  const InitialRegistrationPageView = () => (
    <Paper elevation={3} style={{ padding: '60px' ,width:"500px" , marginTop:"20px"}}>
      <Typography variant="h4" gutterBottom>
        Astrologer Registration
      </Typography>
      <form onSubmit={onSubmitAstrologerDetails} style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <TextField
          required
          label="Mobile Number"
          variant="outlined"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          style={{width:"320px" ,marginTop:"15px",     paddingBottom: "5px", }}
        />
        <TextField
          required
          label="Full Name"
          variant="outlined"
          value={astrologerName}
          onChange={(e) => setAstrologerName(e.target.value)}
          style={{width:"320px" ,marginTop:"10px" , paddingBottom:"10px"}}
        />
        <TextField
          required
          label="Experience In Years"
          variant="outlined"
          value={experience}
          onChange={(e) => setExperience(e.target.value)}
          style={{width:"320px" ,marginTop:"15px"}}
        />
        <FormControl style={{width:"320px" ,marginTop:"15px"}}>
          <InputLabel>Languages Known</InputLabel>
          <Select
            multiple
            value={knownLanguages}
            onChange={(e) => handleChange(e, setKnownLanguages)}
            renderValue={(selected) => selected.join(', ')}
          >
            {['English', 'Hindi', 'Telugu', 'Tamil'].map((language) => (
              <MenuItem key={language} value={language}>
                <Checkbox checked={knownLanguages.indexOf(language) > -1} />
                <ListItemText primary={language} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{width:"320px" ,marginTop:"15px"}}>
          <InputLabel>Expertization</InputLabel>
          <Select
            multiple
            value={expertIn}
            onChange={(e) => handleChange(e, setExpertIn)}
            renderValue={(selected) => selected.join(', ')}
          >
            {['Astrology', 'Horoscope', 'Vastu', 'Face Reading', 'Numerology'].map((expert) => (
              <MenuItem key={expert} value={expert}>
                <Checkbox checked={expertIn.indexOf(expert) > -1} />
                <ListItemText primary={expert} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{width:"320px" ,marginTop:"15px"}}>
          <InputLabel>Category</InputLabel>
          <Select
            multiple
            value={category}
            onChange={(e) => handleChange(e, setCategory)}
            renderValue={(selected) => selected.join(', ')}
          >
            {['All', 'Career', 'Marriage', 'Love', 'Finance', 'Education', 'Health', 'Legal'].map((cat) => (
              <MenuItem key={cat} value={cat}>
                <Checkbox checked={category.indexOf(cat) > -1} />
                <ListItemText primary={cat} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <input type="file" style={{width:"320px"}} onChange={onChangeProfileImage} />
        {imageUrl && (
          <div style={{ position: 'relative', display: 'inline-block', marginTop: '10px' , width:"320px"}}>
            <Avatar src={imageUrl} alt="Preview" style={{ width: '50px', height: '50px' }} />
            <Button
              onClick={handleRemoveImage}
              variant="outlined"
              color="error"
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                minWidth: '30px',
                padding: '0',
                margin: '0',
              }}>
              &times; 
            </Button>
          </div>
        )}
        <FormControlLabel
          control={<Checkbox required />}
          label="I Agree to the Terms and Conditions"
        />
        <Button type="submit" variant="contained" color="primary" fullWidth>
          Submit
        </Button>
      </form>
    </Paper>
  );

  return (
    <>
      <Header />
      <div style={{ padding: '20px' , display:"flex" , flexdirection:"column" , justifyContent:"Center" ,marginTop:"50px"}}>
        {isRegistered ? RegisterSuccessView() : InitialRegistrationPageView()}
      </div>
      <FooterSection />
    </>
  );
};

export default AstrologerRegistration;
