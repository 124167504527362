import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeeScreeen from './HomeeScreeen';
import TermsandConditions from './TermsandConditions';
import PrivacyPolicy from './PrivacyPolicy';
import Refundpolicy from './Refundpolicy';
import ContactUs from './Contactus';
import Disclaimer from './Disclaimer';
import AstrologerRegistration from './AstrologerRegistration';
import Dailyhoroscope from './DailyHoroscope';
import BlogSection from './BlogSection';
import TalkToAStrologer from './TalkToAstrologer';
import ChatWithAstrologer from './ChatWithAstrologer';
import ZodiacSignsDetailed from './ZodiacSignsDetailed';
const Routing = () => (
    <Router>
    <Routes>
      <Route path="/" element={<HomeeScreeen />} />
      <Route path="/terms" element={<TermsandConditions />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/refund" element={<Refundpolicy />} />
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/AstrologerRegistration" element={<AstrologerRegistration />} />
      <Route path="/day-horoscope" element={<Dailyhoroscope/>}/>
      <Route path="/blog" element={<BlogSection/>}/>
      <Route  path='/talkWithAstrologer' element={<TalkToAStrologer/>}/>
      <Route  path='/chatWithAstrologer' element={<ChatWithAstrologer/>}/>
      <Route path="/Zodiac-Signs" element={<ZodiacSignsDetailed/>}/>
    </Routes>
  </Router>
);

export default Routing;
