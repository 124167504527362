import styles from './styles/horoscopes.module.css'
import zodiacImages from './ZoidacImage'
const YesterdaysHoroscopes=(props)=>{
    const {yesterdayHoroscope} = props
    const {sign,horoscope} = yesterdayHoroscope
    const zodiacImage = zodiacImages[sign]
    return (
        <div className={styles.horoscopeCard}>
            <div className={styles.horscopeNameAndImage}>
                <img src={zodiacImage} />
                <h1>{sign}</h1>
            </div>
            
            <p>{horoscope}</p>
        </div>
    )
}

export default YesterdaysHoroscopes