import React, { createContext, useState } from 'react';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);  
  const loginUser = (userData) => setUser(userData); 
  const logoutUser = () => setUser(null); 
console.log(user)

  return (
    <AppContext.Provider value={{ user, loginUser,logoutUser}}>
    {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
