import styles from './styles/ZodiacSigns.module.css'
import Header from "./Header"
import FooterSection from "./FooterSection"

import zodiacImages from './ZoidacImage'
import {useState} from 'react'
const ZodiacSignsDetailed=()=>{

    const [activeZodiactValue,setActiveZodiacValue] = useState('')

    const onClickToGetValue=(event)=>{
        setActiveZodiacValue(event.target.innerText)
    }


    const getAriesData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Aries"]} />
            <h1>Aries</h1>
            <hr/>
            <div >
                <p>Aries (March 21 - April 19) is the first sign of the zodiac, symbolized by the Ram. 
                As a fire sign, Aries is associated with energy, action, and enthusiasm. People born under Aries are often seen as natural leaders, driven by their passion and determination to take initiative. 
                They are bold, adventurous, and unafraid to face challenges head-on.Aries individuals tend to be highly independent, preferring to carve their own path rather than follow others. 
            </p>
            <p>

                They thrive on excitement and are always eager to start new projects or embrace new experiences. However, their impulsive nature can sometimes lead them to act without fully considering the consequences. 
                This spontaneity, combined with their competitive spirit, means they are always striving to be the best in whatever they do.
            </p>
            <p>
                In relationships, Aries is passionate and direct. They value honesty and are often the first to express their feelings. However, their strong-willed nature can sometimes lead to conflicts, especially when they feel restricted or held back.
                Overall, Aries represents the energy of new beginnings, courage, and self-confidence, embodying a zest for life that inspires others to take action.</p>
            </div>
        </div>
    )
    const getTaurusData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Taurus"]} />
            <h1>Taurus</h1>
            <hr/>
            <div>
                <p>Taurus (April 20 - May 20) is the second sign of the zodiac, symbolized by the Bull. As an earth sign, Taurus is associated with stability, reliability, and a love for the material comforts of life. 
                People born under Taurus are often practical, patient, and grounded, valuing security and consistency in both their personal and professional lives.
                </p>
                <p>
                Taurus individuals are known for their determination and persistence. 
                Once they set their sights on a goal, they will work steadily and tirelessly to achieve it, often exhibiting great endurance. Their methodical approach allows them to build strong foundations, whether in relationships, careers, or financial matters. 
                However, this persistence can sometimes turn into stubbornness, making it difficult for them to change course or adapt to new situations.Tauruses are also known for their deep appreciation of beauty, art, and luxury. 
                They often seek comfort in the pleasures of life, such as good food, beautiful surroundings, and sensual experiences.
                </p>
                <p>
                 Their strong connection to the physical world means they enjoy indulging in activities that appeal to their senses.In relationships, Taurus is loyal, dependable, and affectionate. 
                They value stability and security in their partnerships and are often committed and long-term in their relationships. However, they may resist change or challenges to their routine, as they prefer to maintain a sense of order and predictability.Overall, Taurus represents steadfastness, practicality, and a deep connection to the material world, providing a sense of stability and comfort to those around them.</p>
            </div>
        </div>
    )
    const getGeminiData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Gemini"]}/>
            <h1>Gemini</h1>
            <hr/>
            <div>
                <p>Gemini (May 21 - June 20) is the third sign of the zodiac, symbolized by the Twins. As an air sign, Gemini is associated with communication, intellect, and versatility. 
                People born under this sign are often curious, adaptable, and quick-witted, thriving on new experiences and ideas.
                </p>
                <p>
                Geminis are known for their dual nature, which reflects their ability to see multiple perspectives and adapt to different situations easily. 
                They are social butterflies, often enjoying deep conversations, exchanging ideas, and connecting with a wide range of people. 
                </p>
                <p>
                Their intellectual curiosity drives them to learn new things, and they often have a variety of interests or hobbies.
                 This can make them appear scattered or inconsistent at times, as they are always eager to explore something new.
                Because of their adaptable and expressive nature, Geminis are skilled communicators. 
                </p>
                <p>
                They thrive in environments that allow them to share their thoughts and engage in creative expression. However, their restless energy can sometimes lead to indecision or difficulty focusing on one task for an extended period.
                In relationships, Geminis are playful, charming, and fun-loving. 
                </p>
                <p>
                They seek intellectual stimulation and enjoy partners who can keep up with their fast-paced minds. While they value personal freedom, they are also capable of deep connections, as long as they feel mentally engaged and emotionally supported.
                Overall, Gemini represents curiosity, adaptability, and the exchange of ideas, embodying the spirit of change and flexibility that allows them to navigate life's complexities with ease.</p>
            </div>
        </div>
    )
    const getCancerData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Cancer"]}/>
            <h1>Cancer</h1>
            <hr/>
            <div>
                <p>Cancer (June 21 - July 22) is the fourth sign of the zodiac, symbolized by the Crab. As a water sign, Cancer is deeply connected to emotions, intuition, and nurturing. People born under Cancer are often empathetic, protective, and highly sensitive to the needs and feelings of others.
                    Cancers are known for their strong connection to home and family, valuing security, comfort, and close relationships above all else. 
                  </p>
                  <p>  
                    They have a natural instinct to care for those they love, often acting as the emotional support in their social and familial circles. Their nurturing nature makes them compassionate and understanding, but also prone to mood swings, as they are ruled by the moon, which influences their emotional tides.
                    While they may appear tough or reserved on the outside, much like the crab’s shell, Cancer individuals are deeply sensitive and vulnerable beneath their protective exterior. 
                    </p>
                    <p>
                    They often seek emotional safety and can retreat into their shell when they feel threatened or overwhelmed. This cautious approach sometimes makes it hard for them to open up to others.
                    In relationships, Cancer is loyal, affectionate, and deeply committed. 
                    </p>
                    <p>
                    They crave emotional connection and tend to form strong bonds with those they care about. However, their sensitivity can make them easily hurt, and they may withdraw if they feel unappreciated or misunderstood.
                    Overall, Cancer represents emotional depth, intuition, and the desire for security and nurturing. They are compassionate caregivers, providing a sense of emotional stability and comfort to those around them.</p>
            </div>
        </div>
    )
    const getLeoData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Leo"]}/>
            <h1>Leo</h1>
            <hr/>
            <div>
                <p>
                Leo (July 23 - August 22) is the fifth sign of the zodiac, symbolized by the Lion. As a fire sign, Leo is associated with confidence, creativity, and a strong sense of leadership. 
                People born under Leo are often charismatic, bold, and have a natural flair for self-expression, enjoying the spotlight and thriving in social situations.
                Leos are known for their warm-heartedness, generosity, and enthusiasm. 
                </p>
                <p>
                They radiate a powerful energy that draws others to them, often inspiring admiration and loyalty from friends and family.
                Leos love to be recognized for their talents and achievements, and they enjoy making an impact on the world around them. Their ambition and determination to succeed can make them effective leaders, whether in personal relationships or professional settings.
                While they have a strong sense of self and confidence, Leos also have a deep need for validation and appreciation. They can sometimes be sensitive to criticism or feel hurt when they feel unappreciated. 
                </p>
                <p>
                However, their resilient nature typically helps them bounce back quickly from setbacks.
                In relationships, Leos are passionate, loyal, and protective of their loved ones. 
                </p>
                <p>
                They enjoy grand gestures of love and affection, often going out of their way to make their partner feel special. However, they can also be somewhat demanding in their desire for attention and admiration.
                Overall, Leo represents creativity, passion, and self-expression. Their strong, vibrant personality often serves as a source of inspiration and motivation for those around them, embodying the qualities of leadership, warmth, and generosity.
                </p>
            </div>
        </div>
    )

    const getLibraData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Libra"]}/>
            <h1>Libra</h1>
            <hr/>
            <div>
               <p>Libra (September 23 - October 22) is the seventh sign of the zodiac, symbolized by the Scales. As an air sign, Libra is associated with balance, harmony, and a strong sense of justice. People born under Libra are often diplomatic, fair-minded, and focused on maintaining peace in their relationships and environments.
                    Libras are known for their love of balance and symmetry, striving for harmony in all aspects of life. They have a natural ability to see multiple perspectives in any situation, which makes them excellent mediators and negotiators.
                </p>
                <p>
                     This desire for fairness extends to their relationships, where they prioritize equality, mutual respect, and emotional harmony.
                    Ruled by Venus, the planet of love and beauty, Libras are often drawn to art, culture, and aesthetics. 
                    </p>
                    <p>
                    They have a refined sense of style and enjoy surrounding themselves with beauty, whether it's in their environment, personal appearance, or relationships. This appreciation for beauty often reflects in their graceful and charming demeanor, making them well-liked in social settings.
                    In relationships, Libras are affectionate, romantic, and deeply invested in creating meaningful connections.
                    </p>
                    <p>
                     They value partnership and tend to seek out relationships that bring balance and support. However, their desire to please others can sometimes lead to indecisiveness or avoiding conflict, as they dislike upsetting the harmony they strive to maintain.
                    Overall, Libra represents diplomacy, grace, and a strong sense of fairness. Their natural charm and ability to create balance in their surroundings make them skilled at fostering positive relationships and environments. They embody the pursuit of peace, beauty, and equality in both their personal and social lives.</p>
            </div>
        </div>
    )
    const getPiscesData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Pisces"]}/>
            <h1>Pisces</h1>
            <hr/>
            <div>
                <p>Pisces (February 19 - March 20) is the twelfth and final sign of the zodiac, symbolized by two fish swimming in opposite directions. As a water sign, Pisces is associated with emotion, intuition, and spiritual depth.
                </p>
                <p>
                 People born under Pisces are often compassionate, imaginative, and deeply empathetic, with a strong connection to the unseen and emotional realms.
                Pisces are known for their sensitive and dreamy nature. 
                </p>
                <p>
                They are highly intuitive, often absorbing the emotions and energies of those around them. This deep empathy allows them to connect with others on a profound level, making them natural healers and caretakers. However, their sensitivity can also make them vulnerable to emotional overwhelm or escapism when life's challenges become too intense.
                Creativity and imagination play a central role in the lives of Pisces. 
                </p>
                <p>
                Many are drawn to artistic and spiritual pursuits, using their creative gifts to express their inner world. They often have a rich inner life, filled with dreams, visions, and a sense of connection to something greater than themselves. This connection to the mystical often leads Pisces to explore spirituality, seeking answers to life's deeper questions.
                In relationships, Pisces are compassionate, caring, and deeply romantic. 
                </p>
                <p>
                They give generously of themselves, often putting their partner's needs ahead of their own. However, they can sometimes struggle with setting boundaries, leading to emotional exhaustion. Pisces thrive in relationships where emotional depth and mutual understanding are prioritized.
                Overall, Pisces represents compassion, intuition, and spiritual insight. Their ability to navigate the emotional and unseen realms makes them deeply understanding and supportive of others. Pisces embody the qualities of empathy, creativity, and a deep connection to the mysteries of life.
                </p>
            </div>
        </div>
    )
    const getAquariusData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Aquarius"]}/>
            <h1>Aquarius </h1>
            <hr/>
            <div>
                <p>
                Aquarius (January 20 - February 18) is the eleventh sign of the zodiac, symbolized by the Water Bearer. As an air sign, Aquarius is associated with innovation, independence, and humanitarian ideals. People born under Aquarius are often forward-thinking, unconventional, and highly intellectual, with a deep interest in social causes and the future of humanity.
                Aquarius is known for its progressive mindset and desire to bring about positive change in the world.
                </p>

                <p>
                 Aquarians are natural visionaries, often ahead of their time, and they tend to think outside the box. They are drawn to new ideas, technology, and ways to improve society. Their independent nature makes them free thinkers who value individuality and personal freedom, both for themselves and others.
                While Aquarius is often perceived as detached or aloof, this is due to their strong focus on intellectual pursuits and big-picture thinking.
                </p>
                <p>
                 They are more concerned with universal truths and social progress than with personal emotions. However, they are deeply committed to making the world a better place and are known for their humanitarian efforts. They are often involved in causes related to social justice, equality, and innovation.
                In relationships, Aquarians are open-minded and value intellectual connection. 
                </p>
                <p>
                They seek partners who can engage in deep conversations and share their ideals. While they may not be overly emotional or traditional in love, they are loyal and supportive once committed. Their friendships and partnerships are often based on mutual respect and shared vision for the future.
                Overall, Aquarius represents innovation, independence, and a strong sense of social responsibility. They are forward-thinking individuals who aim to bring positive change to the world. With their inventive nature and progressive mindset, Aquarians are always looking to push boundaries and challenge the status quo for the betterment of society.
                </p>
            </div>
        </div>
    )
    const getVirgoData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Virgo"]}/>
            <h1>Virgo</h1>
            <hr/>
            <div>
                <p>
                Virgo (August 23 - September 22) is the sixth sign of the zodiac, symbolized by the Maiden. As an earth sign, Virgo is associated with practicality, precision, and a strong sense of duty. People born under Virgo are often detail-oriented, analytical, and deeply committed to helping others.
                Virgos are known for their methodical approach to life, thriving on organization and efficiency. 
            </p>
            <p>
                They have a sharp intellect and a keen eye for detail, which makes them excellent problem solvers. Virgos are often perfectionists, setting high standards for themselves and others, which can sometimes lead to overthinking or being overly critical. However, their meticulous nature also makes them reliable and hardworking, especially in tasks that require focus and dedication.
                Despite their practical and grounded nature, Virgos are deeply caring and compassionate. 
                </p>
            <p>
                They have a strong desire to be of service to others, often going out of their way to support loved ones in practical and emotional ways. Their nurturing tendencies are expressed through acts of service, and they take pride in being helpful and resourceful.
                In relationships, Virgos are loyal, supportive, and attentive to their partner's needs.
            </p>
            <p>
                 They may not always express their emotions openly, but they show their love through thoughtful actions and gestures. They value stability and honesty in relationships, preferring partners who share their grounded approach to life.
                Overall, Virgo represents diligence, humility, and service. They are practical problem solvers who strive for perfection and aim to bring order and purpose to everything they do. Virgos embody qualities of care, reliability, and a deep sense of responsibility to the world around them.
                </p>
            </div>
        </div>
    )
    const getScorpio=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Scorpio"]}/>
            <h1>Scorpio</h1>
            <hr/>
            <div>
                <p>
                Scorpio (October 23 - November 21) is the eighth sign of the zodiac, symbolized by the Scorpion. As a water sign, Scorpio is associated with intensity, passion, and emotional depth. People born under Scorpio are known for their determination, strong will, and magnetic presence. They are often perceived as mysterious and can be deeply intuitive and insightful.
                Scorpios are known for their intensity in everything they do. 
                They approach life with a fierce passion, whether in their relationships, careers, or personal interests. This passion often leads to a desire for transformation and growth, and Scorpios are not afraid to face challenges head-on. They are highly resilient, capable of navigating even the toughest situations with determination and strength.
                Emotional depth is a defining trait of Scorpio. While they may not always show their emotions on the surface, Scorpios feel things deeply.
            </p>
            <p>
                 They are highly perceptive and can often sense what others are feeling, even when those emotions are hidden. This makes them empathetic and understanding, though they tend to keep their own vulnerabilities guarded until they trust someone completely.
                Ruled by Pluto, the planet of transformation, Scorpios are drawn to life's mysteries and the concept of rebirth. They are naturally curious about the deeper aspects of life and often seek out meaning in areas like psychology, spirituality, or personal development. This desire for depth and transformation makes them powerful forces of change, both in their own lives and in the lives of those around them.
                In relationships, Scorpios are deeply loyal, passionate, and protective. 
            </p>
            <p>
                They value trust and honesty above all else and expect the same from their partners. However, their intensity can sometimes lead to possessiveness or jealousy if they feel threatened. Once committed, Scorpios are devoted and will go to great lengths to support and protect the people they care about.
                Overall, Scorpio represents transformation, intensity, and emotional power. With their strong will and deep insight, Scorpios have the ability to profoundly influence those around them and navigate life’s complexities with grace and determination. Their passionate nature and desire for truth make them deeply compelling and transformative individuals.
                </p>
            </div>
        </div>
    )
    const getSagittarusData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Sagittarius"]}/>
            <h1>Sagittarus</h1>
            <hr/>
            <div>
                <p>
                    Sagittarius (November 22 - December 21) is the ninth sign of the zodiac, symbolized by the Archer, representing the pursuit of knowledge, adventure, and freedom. As a fire sign, Sagittarius is known for its optimism, enthusiasm, and boundless curiosity. People born under Sagittarius are often seen as explorers—both of the world and of the mind—seeking out new experiences, ideas, and perspectives.
                    Sagittarians are natural adventurers, always looking for ways to expand their horizons. Whether through travel, education, or philosophical discussions, they have a deep desire to explore and understand the world around them. This curiosity drives them to question everything, constantly seeking new knowledge and wisdom. They are known for their open-mindedness and are always eager to learn and experience new things.
                    Optimism is a defining trait of Sagittarius. </p>
                    <p>They tend to look on the bright side of life and believe that anything is possible. This positive attitude can be contagious, making them inspiring and uplifting to be around. Sagittarians are not easily discouraged by setbacks; instead, they see challenges as opportunities for growth and learning.
                    Freedom is essential for Sagittarius. They value their independence and resist any attempts to limit their personal or intellectual freedom. This need for autonomy can make them restless or impatient with routine, as they are always seeking new adventures or challenges. However, their independent spirit also makes them self-reliant and confident in their ability to overcome obstacles.
                    In relationships, Sagittarians are playful, open, and adventurous.</p>
                    <p> They value honesty and open communication and are often drawn to partners who share their love for exploration and growth. While they may shy away from emotional intensity or clinginess, they are loyal and supportive once they feel their need for freedom is respected. Sagittarius tends to bring excitement and positivity into their relationships, keeping things light-hearted and fun.
                    Sagittarius represents a love for life, learning, and exploration. With their adventurous spirit, positive outlook, and thirst for knowledge, Sagittarians inspire others to see the world as full of possibilities and to embrace new experiences with an open heart and mind. Their optimism and enthusiasm make them natural leaders and visionaries, always looking forward to the next big adventure.
                </p>
            </div>
        </div>
    )
    const getCapriconData=()=>(
        <div className={styles.zodiacContent}>
            <img src={zodiacImages["Capricon"]}/>
            <h1>Capricon</h1>
            <hr/>
            <div>
                <p>
                Capricorn (December 22 - January 19) is the tenth sign of the zodiac, symbolized by the Goat, representing ambition, discipline, and practicality. As an earth sign, Capricorn is known for its hardworking, responsible, and goal-oriented nature. People born under Capricorn are often seen as determined individuals who prioritize long-term success, stability, and achievement.
                
                Capricorns are highly ambitious and take their responsibilities seriously. They have a strong desire to achieve their goals and are willing to put in the necessary effort, even if it requires patience and persistence. This makes them excellent planners and strategists who are capable of overcoming obstacles through sheer determination. Their focus on success often leads them to careers where they can rise to leadership positions or take on significant responsibilities.
                One of the defining traits of Capricorn is discipline. They are known for their ability to stay focused on their objectives, even in the face of distractions or challenges. Capricorns are practical and pragmatic, preferring to approach life with a realistic mindset. They understand that success often requires hard work and time, and they are not afraid to put in the necessary effort to reach their goals.
                Stability and security are important to Capricorns. </p>
                <p>They are often focused on building a solid foundation in life, whether in their career, relationships, or personal development. Capricorns are generally cautious when making decisions, preferring to weigh the pros and cons before taking action. This careful approach allows them to avoid unnecessary risks and maintain control over their environment.
                In relationships, Capricorns are loyal, dependable, and supportive.</p>
                <p>They may not be overly expressive with their emotions, but they show their love and care through actions and commitment. They are drawn to partners who share their values of stability and ambition, and they appreciate relationships that are grounded in mutual respect and long-term goals.
                While Capricorns can sometimes be seen as serious or reserved, they also have a strong sense of humor and enjoy moments of relaxation once their work is done. They value tradition and are often deeply connected to their family and heritage, finding comfort in routines and familiar structures.
                Overall, Capricorn represents ambition, discipline, and the pursuit of success. With their strong work ethic, practical mindset, and determination, Capricorns are well-equipped to achieve their goals and create a stable, fulfilling life. Their sense of responsibility and commitment makes them reliable individuals who others can count on for support and guidance.
                </p>
            </div>
        </div>
    )
    const getInitialData=()=>(
        <div className={styles.zodiacContent}>
                <p>Zodiac signs are rooted in the belief that the position of celestial bodies—especially the sun, moon, and planets—at the time of a person's birth can impact their personality, emotions, and life experiences. 
                The twelve signs, which correspond to different times of the year, are divided into four elemental groups: fire, earth, air, and water. 
                Each element is thought to influence certain characteristics—such as passion, stability, intellect, or emotion—that shape an individual’s outlook and behavior.
                In everyday life, many people look to their zodiac sign as a tool for personal insight and growth. Zodiac signs offer a framework for understanding how we relate to others, how we handle challenges, and how we can harness our natural strengths. They are often used to explain why people have different approaches to life and provide clues to compatibility in romantic relationships, friendships, and even professional environments. 
                By exploring the traits of their zodiac sign, people can gain a deeper understanding of their instincts, motivations, and desires.</p>
            
            <p>
                Astrology enthusiasts also use zodiac signs to forecast potential life events through horoscopes, which predict future trends based on the movement of planets.
                While these predictions are not definitive, they offer guidance on navigating specific periods in life, such as times of change, emotional growth, or decision-making.
                For many, zodiac signs create a sense of connection to the broader universe, providing a spiritual or metaphysical lens through which to view life. They invite reflection on one's purpose, potential, and place in the cosmos, fostering a sense of meaning and interconnectedness beyond the material world. 
                Ultimately, zodiac signs can serve as a tool for self-exploration, personal development, and a deeper connection to life's mysteries.
            </p>
            <p>
            In many traditions, the zodiac is seen as a cosmic map that helps people align with their life purpose. 
            The signs provide a framework for understanding how we interact with the world and how external forces may shape our internal experiences. 
            For instance, those who study astrology believe that the characteristics assigned to each zodiac sign influence how individuals respond to life's challenges, how they nurture their relationships, and how they pursue their passions and goals.
            Beyond personal growth, zodiac signs often play a role in how people relate to one another. Compatibility between signs is a popular topic, where certain signs are thought to harmonize better with others due to shared qualities or complementary energies.
             This can guide individuals in choosing romantic partners, friends, or even collaborators in professional settings. Zodiac-based insights are often used to navigate misunderstandings and improve communication by understanding another person's core traits.
            </p>
                </div>
    )
    const getTheRelatedZodiacSignDetails=()=>{
        switch(activeZodiactValue){
            case "Aries" :
                return getAriesData()
                break;
            case "Taurus":
                return getTaurusData()
                break; 
            case "Gemini":
                return getGeminiData()
                break;
            case "Cancer":
                return getCancerData()
                break;
            case "Leo":
                return getLeoData()
                break;
            case "Virgo":
                return getVirgoData()
                break;
            case "Libra":
                return getLibraData()
                break;
            case "Scorpio":
                return getScorpio()
                break;
            case "Sagittarius":
                return getSagittarusData()
                break;
            case "Capricon":
                return getCapriconData()
                break;
            case "Aquarius":
                return getAquariusData()
                break;
            case "Pisces":
                return getPiscesData()
            default:
                return getInitialData()
                break;    
        }
    }

    return (

        <>
        <Header/>
            <div className={styles.ZodiacContainer}>
            <div className={styles.SignsSection}>
                <div className={styles.signsContainer}>
                    <h2>Zodiac Signs</h2>
                    <p className={activeZodiactValue==="Aries" ? styles.activeItem : ""} onClick={onClickToGetValue}>Aries</p>
                    <p className={activeZodiactValue==="Taurus" ? styles.activeItem : ""} onClick={onClickToGetValue}>Taurus</p>
                    <p className={activeZodiactValue==="Gemini" ? styles.activeItem : ""} onClick={onClickToGetValue}>Gemini</p>
                    <p className={activeZodiactValue==="Cancer" ? styles.activeItem : ""} onClick={onClickToGetValue}>Cancer</p>
                    <p className={activeZodiactValue==="Leo" ? styles.activeItem : ""} onClick={onClickToGetValue}>Leo</p>
                    <p className={activeZodiactValue==="Virgo" ? styles.activeItem : ""} onClick={onClickToGetValue}>Virgo</p>
                    <p className={activeZodiactValue==="Libra" ? styles.activeItem : ""} onClick={onClickToGetValue}>Libra</p>
                    <p className={activeZodiactValue==="Scorpio" ? styles.activeItem : ""} onClick={onClickToGetValue}>Scorpio</p>
                    <p className={activeZodiactValue==="Sagittarius" ? styles.activeItem : ""} onClick={onClickToGetValue}>Sagittarius</p>
                    <p className={activeZodiactValue==="Capricon" ? styles.activeItem : ""} onClick={onClickToGetValue}>Capricon</p>
                    <p className={activeZodiactValue==="Aquarius" ? styles.activeItem : ""} onClick={onClickToGetValue}>Aquarius</p>
                    <p className={activeZodiactValue==="Pisces" ? styles.activeItem : ""} onClick={onClickToGetValue}>Pisces</p>
                    </div>
                    <hr className={styles.barline}/>
                </div>
                
                {getTheRelatedZodiacSignDetails()}
                
            </div>

        <FooterSection/>
        </>
    )
}

export default ZodiacSignsDetailed