// src/ZodiacSign
const zodiacImages = {
    Aries: require('./ZodiacSigns/Aries.jpg'),
    Taurus: require('./ZodiacSigns/Taurus.png'),
    Gemini: require('./ZodiacSigns/Gemini.png'),
    Cancer: require('./ZodiacSigns/Cancer.png'),
    Leo: require('./ZodiacSigns/Leo.png'),
    Virgo: require('./ZodiacSigns/Virgo.png'),
    Libra: require('./ZodiacSigns/Libra.png'),
    Scorpio: require('./ZodiacSigns/Scorpio.jpg'),
    Sagittarius: require('./ZodiacSigns/Sagittarus.jpg'),
    Capricorn: require('./ZodiacSigns/Capricon.png'),
    Aquarius: require('./ZodiacSigns/Aquarius.jpg'),
    Pisces: require('./ZodiacSigns/Pisces.jpg'),
};

export default zodiacImages;
