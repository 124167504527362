import React, { useState, useContext } from 'react';
import axios from 'axios';
import { IoCloseSharp } from "react-icons/io5";
import Popup from 'reactjs-popup';
import styles from './styles/Homeescreen.module.css'
import user from './images/User.png'
import { auth, googleProvider } from './firebase.js'; 
import { signInWithPopup } from 'firebase/auth';
import { AppContext } from './context/AppContext.js'; 
import "./userLogin.css";
import Cookies from "js-cookie"
import Loader from './Loader.jsx';


const UserLogin = () => {
    const { loginUser } = useContext(AppContext); 
    const [phone_number, setPhoneNumber] = useState('');
    const [gotOtp, setGotOtp] = useState(false);
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [newLogin, setNewLogin] = useState(false);
    const [userName, setUserName] = useState('');
    const [showNamePopup, setShowNamePopup] = useState(false);
    const [token, setToken] = useState('');
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);

    const getOtpApi = "https://api.astroping.com/api/user/send-otp";
    const verifyOtpApi = "https://api.astroping.com/api/user/otp-verify";
    const registerUsernameApi = "http://3.94.90.155:8088/api/astropingWebsite/register-username";
    

    const resetForm = () => {
        setPhoneNumber('');
        setOtp('');
        setGotOtp(false);
        setErrorMessage('');
        setLoading(false);
        setNewLogin(false);
        setUserName('');
        setShowNamePopup(false);
        setToken('');
        setIsLoginPopupOpen(false);
    };


    const onClickToLogout=()=>{
        Cookies.remove("jwt_token")
        window.location.reload()
    }

    const handleGetOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(getOtpApi, { phone_number });
            console.log(phone_number)
            if (response.status === 200) {
                setGotOtp(true);
                setErrorMessage('');
            } else {
                setErrorMessage('Failed to send OTP.');
            }
        } catch (error) {
            setErrorMessage('Error sending OTP.');
        }
        setLoading(false);
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(verifyOtpApi, { phone_number, otp });
            console.log(response);
            if (response.data.status === 200) {
                setErrorMessage('');
                setNewLogin(response.data.newLogin);
                setToken(response.data.token);

                if (response.data.newLogin) {
                    setShowNamePopup(true);
                } else {
                    console.log(response)
                    Cookies.set("jwt_token", response.data.token)
                    loginUser({ phone_number, userName: response.data.user.userName });
                    resetForm();
                }
            } else {
                setErrorMessage('Invalid OTP.');
            }
        } catch (error) {
            setErrorMessage('Error verifying OTP.');
        }
        setLoading(false);
    };

    const handleNewUserRegister = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(registerUsernameApi, { token, userName });
            if (response.data.success) {
              
                loginUser({ phone_number, userName });
                resetForm();
            } else {
                setErrorMessage('Failed to register new user.');
            }
        } catch (error) {
            setErrorMessage('Error registering new user.');
        }
        setLoading(false);
    };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const token = result.user.accessToken; 
            const user = result.user;

            // console.log('Google Access Token:', token);
            // console.log('User Info:', user);
            if (result.user.emailVerified===true){
                loginUser({ phone_number: user.phoneNumber, userName: user.displayName });
                Cookies.set("jwt_token",token)
                resetForm()
            }
           // console.log(result)
          
            
        } catch (error) {
            // console.error('Error during Google sign-in:', error);
            setErrorMessage('Error signing in with Google.');
        }
    };

    const jwtToken = Cookies.get("jwt_token")
    //console.log(jwtToken)
    return (
        <div className={`popup-container ${showNamePopup || isLoginPopupOpen ? 'popup-active' : ''}`}>
            <div className="overlay"></div>
            {jwtToken === undefined ? 
    <button className="trigger-button" onClick={() => setIsLoginPopupOpen(true)}>
        <img src={user} alt="" id={styles.setimageinsideloginbutton}/>Login
    </button>
    : 
    <button className="trigger-button-after-login">
        <img src={user} alt="" id={styles.setimageinsideloginbutton}/>
        <div className="profile-menu">
            <ul>
                <li>Hello!</li>
                <li onClick={onClickToLogout}>Logout</li>
            </ul>
        </div>
    </button>
}
            <Popup
                modal
                open={isLoginPopupOpen}
                closeOnDocumentClick={false}
                onClose={resetForm}
            >
                <div className="popup-container-display">
                    <div className="close-button-container">
                        <IoCloseSharp onClick={resetForm} color='#000' className="close-icon" />
                    </div>
                    <div className="confirm-details">
                        <form>
                            <div className="form-input-label">
                                <label className="lab">Enter Phone Number</label>
                                <br />
                                <input
                                    value={phone_number}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                    type="tel"
                                    placeholder="Enter Mobile Number"
                                    required
                                />
                            </div>
                            {gotOtp && (
                                <div className="form-input-label">
                                    <label className="lab">Enter OTP</label>
                                    <br />
                                    <input
                                        value={otp}
                                        onChange={(e) => setOtp(e.target.value)}
                                        type="tel"
                                        maxLength="4"
                                        pattern="[0-9]*"
                                        placeholder="Enter OTP"
                                        required
                                    />
                                </div>
                            )}
                            {loading ? (
                                <div className="loader-container">
                                    <Loader />
                                </div>
                            ) : gotOtp ? (
                                <button
                                    type="submit"
                                    className="get-otp-btn"
                                    onClick={handleVerifyOtp}
                                >
                                    Submit
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="get-otp-btn"
                                    onClick={handleGetOtp}
                                >
                                    Get OTP
                                </button>
                            )}
                            <p className='or'>(or)</p>
                            <button
                                type="button"
                                className="google-signin-btn" 
                                onClick={handleGoogleSignIn}
                            >
                                <img width="38" height="38" src="https://img.icons8.com/color/48/google-logo.png" className='google-icon' alt="google-logo"/>
                                Sign in with Google
                            </button>
                            {errorMessage && <p className="error-message">{errorMessage}</p>}
                        </form>
                    </div>
                </div>
            </Popup>

            {showNamePopup && (
                <Popup
                    modal
                    open={showNamePopup}
                    closeOnDocumentClick={false}
                    onClose={() => setShowNamePopup(false)}
                >
                    <div className="popup-container-display">
                        <div className="button-container">
                            <IoCloseSharp onClick={() => { setShowNamePopup(false); resetForm(); }} className="close-icon" />
                        </div>
                        <div className="confirm-details">
                            <form>
                                <div className="form-input-label">
                                    <label className="lab">Enter Name</label>
                                    <br />
                                    <input
                                        value={userName}
                                        onChange={(e) => setUserName(e.target.value)}
                                        type="text"
                                        placeholder="Enter Name"
                                        required
                                    />
                                </div>
                                <div className="form-input-label">
                                    <label className="lab">Phone Number</label>
                                    <br />
                                    <input
                                        value={phone_number}
                                        type="tel"
                                        disabled
                                    />
                                </div>
                                {loading ? (
                                    <div className="loader-container">
                                        <Loader />
                                    </div>
                                ) : (
                                    <button
                                        type="submit"
                                        className="get-otp-btn"
                                        onClick={handleNewUserRegister}
                                    >
                                        Register
                                    </button>
                                )}
                                {errorMessage && <p className="error-message">{errorMessage}</p>}
                            </form>
                        </div>
                    </div>
                </Popup>
            )}
        </div>
    );
};

export default UserLogin;
